import {
    Alert,
    Box,
    Button,
    SwipeableDrawer,
    useMediaQuery,
    useScrollTrigger,
    useTheme,
} from '@mui/material';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Loader from '../components/common/Loader';
import { lemonSqueezyCheckoutID } from '../lemonsqueezyConfig';
import { useAuthProvider } from '../providers/AuthProvider';
import { STATUS } from '../services/organizations/status';
import { ROLES } from '../services/users/roles';
import Footer from './Footer';
import NavBar from './NavBar';

const baseUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;

function MainLayout({ children, sidebar, loader }) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { user, organization } = useAuthProvider();
    const trigger = useScrollTrigger();
    const theme = useTheme();
    const upMd = useMediaQuery(theme.breakpoints.up('md'));
    return (
        <>
            <Head>
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                window.chatwootSettings = {
                    type: 'standard',
                    launcherTitle: 'Any questions?'
                };
                (function(d,t) {
                    var BASE_URL="https://app.chatwoot.com";
                    var g=d.createElement(t),s=d.getElementsByTagName(t)[0];
                    g.src=BASE_URL+"/packs/js/sdk.js";
                    g.defer = true;
                    s.parentNode.insertBefore(g,s);
                    g.onload=function(){
                        window.chatwootSDK.run({
                        websiteToken: 'B7ftKqqXbE7rq3WBsqbYGMAX',
                        baseUrl: BASE_URL
                        })
                    }
                })(document,"script");
            `,
                    }}
                />
            </Head>
            <Box>
                <NavBar
                    showSideBarButton={!upMd && !!sidebar}
                    handleClickSidebar={() => setSidebarOpen(!sidebarOpen)}
                />
                <Box
                    sx={{
                        pt: '58px',
                        display: { xs: 'block', md: 'flex' },
                        width: '100%',
                        minHeight: 'calc(100vh - 160px)',
                    }}
                >
                    {!upMd && !!sidebar && (
                        <SwipeableDrawer
                            anchor="left"
                            open={sidebarOpen}
                            onOpen={() => setSidebarOpen(true)}
                            onClose={() => setSidebarOpen(false)}
                        >
                            <Box sx={{ mt: '58px' }}>{sidebar}</Box>
                        </SwipeableDrawer>
                    )}
                    {!!upMd && !!sidebar && (
                        <Box
                            sx={{
                                width: '230px',
                                flex: '0 0 auto',
                                position: 'sticky',
                                top: !trigger ? 58 : 0,
                                height: '100vh',
                                transition: 'all 0.3s',
                                overflow: 'auto',
                                backgroundColor: 'white.light',
                            }}
                        >
                            {sidebar}
                        </Box>
                    )}
                    <Box
                        sx={{
                            width: {
                                xs: '100%',
                                md: sidebar ? 'calc(100% - 230px)' : '100%',
                            },
                            marginLeft: 'auto',
                            marginRight: 'auto',
                        }}
                    >
                        {organization?.status === STATUS.SUSPENDED && (
                            <Alert
                                severity="error"
                                variant="filled"
                                action={
                                    user?.role === ROLES.OWNER && (
                                        <Button
                                            href={`https://greenframe.lemonsqueezy.com/checkout/buy/${lemonSqueezyCheckoutID}?checkout[email]=${encodeURIComponent(
                                                user.email
                                            )}`}
                                            target="_blank"
                                            rel="noreferrer"
                                            color="inherit"
                                            size="small"
                                        >
                                            Subscribe
                                        </Button>
                                    )
                                }
                                sx={{
                                    position: 'absolute',
                                    width: {
                                        xs: '100%',
                                        md: sidebar ? 'calc(100% - 230px)' : '100%',
                                    },
                                    borderRadius: 0,
                                    left: {
                                        xs: 0,
                                        md: sidebar ? '230px' : 0,
                                    },
                                    top: 58,
                                }}
                            >
                                You don&apos;t have an active subscription, you are in
                                read-only mode.
                            </Alert>
                        )}
                        {loader ? (
                            <Loader fullPage={true} />
                        ) : (
                            <Box
                                sx={{
                                    mt: organization?.status === STATUS.SUSPENDED && 6,
                                    mb: 2,
                                    mx: {
                                        xs: 0,
                                        md: sidebar ? 0 : 0,
                                    },
                                }}
                            >
                                {children}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
            <Footer baseUrl={baseUrl} />
        </>
    );
}

MainLayout.propTypes = {
    children: PropTypes.element,
    sidebar: PropTypes.element,
    loader: PropTypes.bool,
};

export default MainLayout;
