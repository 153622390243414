import { Button, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import Slide from '@mui/material/Slide';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import makeStyles from '@mui/styles/makeStyles';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { FiArrowRight, FiMenu } from 'react-icons/fi';

import AvatarMenu from '../components/common/AvatarMenu';
import { useAuthProvider } from '../providers/AuthProvider';
import Logo from './Logo';

function HideOnScroll(props) {
    const { children } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
};

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: theme.palette.primary.main,
    },
    toolbar: {
        backgroundColor: theme.palette.primary.main,
        justifyContent: 'space-between',
        height: '100%',
    },
    title: {
        cursor: 'pointer',
    },
    link: {
        display: 'inline-block',
        textAlign: 'center',
        padding: theme.spacing(1, 2),
        height: '100%',
        cursor: 'pointer',
        fontWeight: 900,
        fontSize: '0.9rem',
        color: theme.palette.dark.main,
        textTransform: 'uppercase',
        transition: '0.3s ease all',
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'none',
        },
        '&::after': {
            content: "''",
            display: 'block',
            width: 0,
            height: '2px',
            backgroundColor: 'transparent',
            transition: '0.3s all ease',
        },
        '&:hover::after': {
            width: `50%`,
            backgroundColor: theme.palette.secondary.main,
        },
    },
    activeLink: {
        color: theme.palette.secondary.dark,
        '&::after': {
            backgroundColor: theme.palette.secondary.main,
            width: '100%',
        },
        '&:hover::after': {
            width: `100%`,
        },
    },
}));

const MENU_ITEMS_LOGGED = [
    {
        name: 'Documentation',
        href: 'https://docs.greenframe.io',
    },
    {
        name: 'Benchmark',
        href: '/benchmark',
    },
];

const MENU_MOBILE_ITEMS_LOGGED = [
    {
        name: 'Documentation',
        href: 'https://docs.greenframe.io',
    },
    {
        name: 'Benchmark',
        href: '/benchmark',
    },
    {
        name: 'Organization',
        href: '/organization',
    },
    {
        name: 'Settings',
        href: '/settings',
    },
    {
        name: 'Logout',
        href: '/logout',
    },
];

const MENU_ITEMS_UNLOGGED = [
    {
        name: 'Demo',
        href: '/demo',
    },
    {
        name: 'Benchmark',
        href: '/benchmark',
    },
    {
        name: 'Documentation',
        href: 'https://docs.greenframe.io',
    },
];

const MenuItem = ({ href, name }) => {
    const classes = useStyles();
    const router = useRouter();

    const baseElement = (
        <Typography
            className={`${classes.link} ${
                router.pathname === href && classes.activeLink
            }`}
        >
            {name}
        </Typography>
    );

    return (
        <NextLink href={href} passHref>
            {baseElement}
        </NextLink>
    );
};

MenuItem.propTypes = {
    href: PropTypes.string,
    name: PropTypes.string,
};

const NavBar = (props) => {
    const [isMobileMenuOpened, setIsMobileMenuOpened] = useState(false);
    const [menuItems, setMenuItems] = useState(MENU_ITEMS_UNLOGGED);

    const { isLoggedIn } = useAuthProvider();
    const classes = useStyles();
    const upMd = useMediaQuery((theme) => theme.breakpoints.up('md'));
    const router = useRouter();

    useEffect(() => {
        setMenuItems(
            isLoggedIn
                ? upMd
                    ? MENU_ITEMS_LOGGED
                    : MENU_MOBILE_ITEMS_LOGGED
                : MENU_ITEMS_UNLOGGED
        );
    }, [isLoggedIn, upMd]);

    const toggleDrawer = () => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setIsMobileMenuOpened(!isMobileMenuOpened);
    };

    const redirectToLogin = () => {
        router.push('/login');
    };

    return (
        <>
            <HideOnScroll {...props}>
                <AppBar
                    position="fixed"
                    color="default"
                    elevation={2}
                    className={classes.appBar}
                >
                    <Toolbar className={classes.toolbar} variant="dense">
                        {props.showSideBarButton && (
                            <IconButton onClick={props.handleClickSidebar} size="large">
                                <FiArrowRight />
                            </IconButton>
                        )}
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '56px',
                            }}
                        >
                            <Logo monochrome />

                            <Box
                                sx={{
                                    display: { xs: 'none', md: 'block' },
                                    ml: '2rem',
                                    pt: '0.3rem',
                                }}
                            >
                                {menuItems.map((item, index) => (
                                    <MenuItem {...item} key={index} />
                                ))}
                            </Box>
                        </Box>

                        <Box display={{ xs: 'none', md: 'flex' }}>
                            {isLoggedIn ? (
                                <AvatarMenu isLoggedIn={isLoggedIn} />
                            ) : (
                                <Button
                                    variant="outlined"
                                    size="small"
                                    color="dark"
                                    disabled={router.pathname === '/login'}
                                    onClick={() => redirectToLogin()}
                                >
                                    Login
                                </Button>
                            )}
                        </Box>

                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            size="large"
                            sx={{ display: { xs: 'block', md: 'none' } }}
                        >
                            <FiMenu />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>

            <SwipeableDrawer
                anchor="right"
                open={isMobileMenuOpened}
                onClose={toggleDrawer()}
                onOpen={toggleDrawer()}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <Box>
                    <List sx={{ pt: 8 }}>
                        {menuItems.map((item, index) => (
                            <li key={index}>
                                <MenuItem {...item} />
                            </li>
                        ))}
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    );
};

NavBar.propTypes = {
    showSideBarButton: PropTypes.bool,
    handleClickSidebar: PropTypes.func,
};

export default NavBar;
