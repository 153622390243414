import { Box, Container, Link as MUILink, Typography } from '@mui/material';
import Link from 'next/link';
import PropTypes from 'prop-types';

import Logo from './Logo';

const footerLinkStyle = {
    textDecoration: 'none',
    textAlign: 'left',
    color: 'dark.main',
    width: '200px',
    marginLeft: '8px',
    transition: '0.2s ease all',
    '&:hover': {
        color: 'secondary.dark',
    },
};

const Footer = ({ baseUrl }) => {
    return (
        <Box
            as="footer"
            sx={{
                flexShrink: 0,
                width: '100%',
                height: '160px',
                display: 'flex',
                alignItems: 'center',
                color: 'dark.main',
                backgroundColor: 'grey.light',
                borderTop: '1px solid',
                borderColor: 'grey.dark',
            }}
        >
            <Container
                sx={{
                    p: 2,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    flexDirection: { xs: 'column', sm: 'row' },
                }}
            >
                <Box
                    align="center"
                    style={{
                        width: '200px',
                        marginLeft: '8px',
                    }}
                >
                    <Logo monochrome />
                </Box>
                <Box
                    align="center"
                    style={{
                        flex: 1,
                        textAlign: 'center',
                    }}
                >
                    <Typography>
                        Made with ❤ by{' '}
                        <MUILink
                            href="https://marmelab.com"
                            sx={{
                                fontWeight: 700,
                                textDecoration: 'none',
                                color: 'secondary.dark',
                            }}
                        >
                            Marmelab
                        </MUILink>
                    </Typography>
                    <Typography>Copyright 2023</Typography>
                </Box>
                <Box
                    align="center"
                    sx={{
                        display: 'flex',
                        alignItems: 'start',
                        justifyContent: 'space-around',
                        flexDirection: 'column',
                        mt: { xs: 2, sm: 0 },
                    }}
                >
                    <Link href="https://docs.greenframe.io" passHref>
                        <Typography sx={footerLinkStyle} variant="body2">
                            Documentation
                        </Typography>
                    </Link>
                    <Link href="mailto:contact@greenframe.io" passHref>
                        <Typography sx={footerLinkStyle} variant="body2">
                            Contact us
                        </Typography>
                    </Link>

                    <Link href={`${baseUrl}/terms`} passHref>
                        <Typography sx={footerLinkStyle} variant="body2">
                            Terms and conditions
                        </Typography>
                    </Link>

                    <Link href={`${baseUrl}/legals`} passHref>
                        <Typography sx={footerLinkStyle} variant="body2">
                            Legal Mentions
                        </Typography>
                    </Link>

                    <Link href={`${baseUrl}/privacy`} passHref>
                        <Typography sx={footerLinkStyle} variant="body2">
                            Privacy Policy
                        </Typography>
                    </Link>
                </Box>
            </Container>
        </Box>
    );
};

Footer.propTypes = {
    baseUrl: PropTypes.string,
};

export default Footer;
