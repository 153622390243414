import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import Image from 'next/image';
import Link from 'next/link';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    logo: {
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: (props) => ({
        fontSize: '1.4rem',
        marginLeft: '4px',
        fontWeight: 600,
        fontFamily: theme.typography.h1.fontFamily,
        color: props.monochrome ? theme.palette.dark.main : theme.palette.secondary.main,
    }),
    green: {
        color: theme.palette.primary.main,
    },
}));

const Logo = React.forwardRef(({ monochrome }, ref) => {
    const classes = useStyles({ monochrome });
    return (
        <Link href="/">
            <Box className={classes.logo}>
                <Image
                    priority={!monochrome}
                    src={monochrome ? '/logo_monochrome.svg' : '/logo.svg'}
                    alt="Logo of Greenframe.io"
                    width={30}
                    height={30}
                />
                <Typography className={classes.text}>
                    Green<span className={monochrome ? '' : classes.green}>Frame</span>
                </Typography>
            </Box>
        </Link>
    );
});

Logo.displayName = 'Logo';
Logo.propTypes = {
    monochrome: PropTypes.bool,
};

export default Logo;
