import AccountCircle from '@mui/icons-material/AccountCircle';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { IconButton, Menu, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { default as NextLink } from 'next/link';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useState } from 'react';

const MENU_ITEMS = [
    {
        name: 'Organization',
        href: '/organization',
        icon: <GroupWorkIcon color={'disabled'} sx={{ mr: 1 }} />,
    },
    {
        name: 'Settings',
        href: '/settings',
        icon: <SettingsIcon color={'disabled'} sx={{ mr: 1 }} />,
    },
    {
        name: 'Logout',
        href: '/logout',
        icon: <LogoutIcon sx={{ mr: 1 }} />,
        color: 'error',
    },
];

const MenuItem = ({ href, name, icon, color }) => {
    const baseElement = (
        <Typography
            textAlign="left"
            sx={{
                paddingY: '0.5rem',
                paddingX: '0.8rem',
                cursor: 'pointer',
                '&:hover': {
                    backgroundColor: 'grey.main',
                },
            }}
            display="flex"
            justifyContent="flex-start"
            color={color}
        >
            {icon}
            {name}
        </Typography>
    );

    return (
        <NextLink href={href} passHref>
            {baseElement}
        </NextLink>
    );
};

MenuItem.propTypes = {
    href: PropTypes.string,
    name: PropTypes.string,
    icon: PropTypes.element,
    color: PropTypes.string,
};

const AvatarMenu = ({ isLoggedIn }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const { push } = useRouter();
    const handleMenu = (event) => {
        if (isLoggedIn) {
            setAnchorEl(event.currentTarget);
        } else {
            push('/login');
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <Box>
            <IconButton
                aria-label="user menu"
                aria-controls="avatar-menu"
                aria-haspopup="true"
                onClick={handleMenu}
                color="default"
                disabled={!isLoggedIn}
                size="large"
                sx={{ paddingX: 0 }}
            >
                <AccountCircle
                    sx={{ width: '2.1rem', height: '2.1rem', color: 'dark.main' }}
                />
                {isLoggedIn && <KeyboardArrowDownIcon sx={{ color: 'dark.main' }} />}
            </IconButton>
            <Menu
                id="avatar-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <Box>
                    {MENU_ITEMS.map((item, index) => (
                        <MenuItem {...item} key={index} />
                    ))}
                </Box>
            </Menu>
        </Box>
    );
};

AvatarMenu.propTypes = {
    isLoggedIn: PropTypes.bool,
};

export default AvatarMenu;
